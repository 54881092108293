<template>
    <Page icon="mdi-tablet-cellphone" :title="form.name" :progress="progress" :error="error" @reload="load()">

        <v-tabs class="elevation-1">
            <v-tab @click="tab = 0">{{$t('device.tabs.global')}}</v-tab>
            <v-tab @click="tab = 1">{{$t('device.tabs.calibration')}}</v-tab>
            <v-tab @click="tab = 2">{{$t('device.tabs.patient')}}</v-tab>
        </v-tabs>

        <template v-if="tab == 0">
            <ActionBar class="mt-3" :updateTime="updateTime">
                <template v-slot>
                    <v-btn 
                        color="primary"
                        :loading="saveProgress" 
                        :disabled="saveProgress || dangerProgress"
                        @click="save()"
                    >
                        <v-icon left>mdi-content-save-outline</v-icon> {{$t("save")}}
                    </v-btn>
                </template>
            </ActionBar>

            <Card class="mt-3" :title="$t('device.update.title')">
                <v-text-field
                    class="mt-1 mb-3"
                    v-if="user.hasPerm('entity')"
                    :label="$t('device.form.entity')"
                    v-model="entity"
                    hide-details="true"
                    :disabled="saveProgress || dangerProgress || patient !== null"
                    readonly
                    outlined
                    prepend-inner-icon="mdi-office-building"
                    @click="dialogEntity = true"
                />
              
                <v-row>
                    <v-col class="col-12 col-md-6 pt-0">  
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.name')"
                            v-model="form.name"
                            :error-messages="errors.name"
                            :hide-details="!errors.name"
                            :disabled="saveProgress || dangerProgress"
                            @input="validate('name')"
                            prepend-inner-icon="mdi-tablet-cellphone"
                            outlined
                        />
                    </v-col>

                    <v-col class="col-12 col-md-6 pt-0"> 
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.sn')"
                            v-model="form.sn"
                            :error-messages="errors.sn"
                            :hide-details="!errors.sn"
                            :disabled="saveProgress || dangerProgress"
                            @input="validate('sn')"
                            prepend-inner-icon="mdi-counter"
                            outlined
                        />
                    </v-col>
                </v-row>

                <v-text-field
                    class="mt-3"
                    :label="$t('device.form.info')"
                    v-model="form.info"
                    :error-messages="errors.info"
                    :hide-details="!errors.info"
                    :disabled="saveProgress || dangerProgress"
                    @input="validate('info')"
                    prepend-inner-icon="mdi-clipboard-text-outline"
                    outlined
                />
                                
                <v-text-field
                    v-if="token"
                    class="mt-6"
                    :label="$t('device.form.token')"
                    v-model="token"
                    hide-details="true"
                    :disabled="saveProgress || dangerProgress"
                    prepend-inner-icon="mdi-key"
                    append-icon="mdi-content-copy"
                    @click:append="copyToClipboard(token)"
                    outlined
                    readonly
                />

                <v-row v-if="wifi">
                    <v-col>
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.wifi.ssid')"
                            v-model="wifi.ssid"
                            hide-details="true"
                            :disabled="saveProgress || dangerProgress"
                            prepend-inner-icon="mdi-wifi"
                            append-icon="mdi-content-copy"
                            @click:append="copyToClipboard(wifi.ssid)"
                            outlined
                            readonly
                        />
                    </v-col>

                    <v-col>
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.wifi.password')"
                            v-model="wifi.password"
                            hide-details="true"
                            :disabled="saveProgress || dangerProgress"
                            prepend-inner-icon="mdi-lock"
                            append-icon="mdi-content-copy"
                            @click:append="copyToClipboard(wifi.password)"
                            outlined
                            readonly
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.tablet.name')"
                            v-model="form.tablet.name"
                            hide-details="true"
                            :disabled="saveProgress || dangerProgress"
                            prepend-inner-icon="mdi-tablet-cellphone"
                            @click:append="copyToClipboard(wifi.ssid)"
                            outlined
                        />
                    </v-col>

                    <v-col>
                        <v-text-field
                            class="mt-3"
                            :label="$t('device.form.tablet.sn')"
                            v-model="form.tablet.sn"
                            hide-details="true"
                            :disabled="saveProgress || dangerProgress"
                            prepend-inner-icon="mdi-counter"
                            outlined
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-textarea
                            class="mt-0"
                            :label="$t('device.form.additional_devices')"
                            v-model="form.additionalDevices"
                            rows=3
                            auto-grow
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('additionalDevices')"
                            outlined
                            prepend-inner-icon="mdi-note-outline"
                        />
                    </v-col>
                </v-row>

                <v-snackbar
                    v-model="copyAlert"
                    timeout="1000"
                    min-height="40"
                    min-width="220"
                    height="40"
                    width="220"
                    color="white secondary--text"
                    content-class="d-flex justify-center align-center pr-0 pl-0 pt-0 pb-0 mr-0 ml-0"
                > 
                    {{$t('copied_to_clipboard')}} 
                </v-snackbar>
            </Card>

            <DangerZone
                v-if="user.hasPerm('patient')"
                @btnClick="beforeDangerZoneAction()"
                v-model="dialogDangerItem"
                :data="[
                    {
                        itemKey: 'changeStatus',
                        itemName: $t('device.danger_zone.change_status'),
                        itemInfo: $t('device.danger_zone.change_status_info') + ' ',
                        itemInfoGreen: (form.enabled ? $t('active') : null),
                        itemInfoRed: (form.enabled ? null : $t('inactive')),
                        itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false)
                    },

                    {
                        itemKey: 'delete',
                        itemName: $t('device.danger_zone.delete'),
                        itemInfo: $t('device.danger_zone.delete_info'),
                        itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false)
                    }
                ]"
                :disabled="saveProgress || dangerProgress"
            />
        </template>

        <template v-if="tab == 1">
            <ActionBar class="mt-3" :updateTime="calibrationUpdateTime">
                <template v-slot>
                    <v-btn 
                        color="primary"
                        :loading="saveCalibrationProgress" 
                        :disabled="saveCalibrationProgress || dangerProgress"
                        @click="saveCalibration()"
                    >
                        <v-icon left>mdi-content-save-outline</v-icon> {{$t("save")}}
                    </v-btn>
                </template>
            </ActionBar>

            <Card class="mt-3" :title="$t('device.calibration.title')">
                    <v-row>
                        <v-col class="col-12 pt-0">  
                            <span class="ml-1">{{$t('device.calibration.gyro')}}</span>
                            <v-text-field
                                class="mt-3"
                                :label="$t('device.calibration.gyrox')"
                                v-model="form_calibration.calibration_gyro_x"
                                type="number"
                                clearable
                                :error-messages="errors_calibration.calibration_gyro_x"
                                :hide-details="!errors_calibration.calibration_gyro_x"
                                :disabled="saveCalibrationProgress || dangerProgress"
                                @input="validate('calibration_gyro_x')"
                                prepend-inner-icon="mdi-axis-arrow"
                                outlined
                            />
                            <v-text-field
                                class="mt-3"
                                :label="$t('device.calibration.gyroy')"
                                v-model="form_calibration.calibration_gyro_y"
                                type="number"
                                clearable
                                :error-messages="errors_calibration.calibration_gyro_y"
                                :hide-details="!errors_calibration.calibration_gyro_y"
                                :disabled="saveCalibrationProgress || dangerProgress"
                                @input="validate('calibration_gyro_y')"
                                prepend-inner-icon="mdi-axis-arrow"
                                outlined
                            />
                            <v-text-field
                                class="mt-3"
                                :label="$t('device.calibration.gyroz')"
                                v-model="form_calibration.calibration_gyro_z"
                                type="number"
                                clearable
                                :error-messages="errors_calibration.calibration_gyro_z"
                                :hide-details="!errors_calibration.calibration_gyro_z"
                                :disabled="saveCalibrationProgress || dangerProgress"
                                @input="validate('calibration_gyro_z')"
                                prepend-inner-icon="mdi-axis-arrow"
                                outlined
                            />
                        </v-col>
                    </v-row>
            </Card>
        </template>

        <template v-if="tab == 2">
            <Card class="mt-3" :title="$t('device.patient_tab.title')">
                <template v-slot:headerActions v-if="user.hasPerm('patient') && form.patient">
                    <v-btn
                        fab
                        color= 'primary'
                        x-small
                        dark
                        depressed
                        :to="{ name: 'patient', params: { id: form.patient, routefrom: 'devices+device.'+form.id } }"
                        ><v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>

                <v-alert
                    dense
                    type="success"
                    text
                    tile
                    icon="mdi-information-outline"
                    v-if="patientUpdateTime && form.patient"
                >
                    <span class="font-weight-light text-body-2">{{$t('device.patient_tab.assigned_at') + ": "}}</span>
                    <span class="text-body-2">{{patientUpdateTime.toLocaleDateString([], {day: '2-digit', month: '2-digit', year: 'numeric' })}}</span>
                    <span class="font-weight-medium text-body-2" v-if="Date.now() - new Date(patientUpdateTime).getTime() > 0">
                        {{"(" }}<HumanTime 
                            :time="Date.now() - new Date(patientUpdateTime).getTime()"
                            :units= "['d']"
                            :past="true"
                        />{{")"}}
                    </span>
                </v-alert>

                <div class="d-flex justify-center">
                    <v-btn 
                        v-if="!form.patient" 
                        @click="dialog = true" 
                        color="primary"
                    >
                        <v-icon left>mdi-plus</v-icon> {{$t('device.patient_tab.btn_assign')}} 
                    </v-btn>
                </div>

                <PatientVisitingCard :patient="patient" class="mt-0" v-if="form.patient" />

                <v-btn v-if="form.patient" @click="dialogClearPatient = true" color="error" class="mt-3"> {{$t('device.patient_tab.btn_unassign')}} </v-btn>
            </Card>
        </template>

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('device.danger_zone.change_status');
                    break;
                case 'delete':
                    return $t('device.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('device.danger_zone.dialog.change_status_desc');
                    break;
                case 'delete':
                    return $t('device.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('inactive') : $t('active'));
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        />
        
        <Search 
            :v-if="dialog" 
            :open="dialog" 
            :searchProgress="searchProgress"
            :title="$t('device.update.patient_select.title')" 
            :data="patients" 
            :pagination="pagination_patients"
            :initLimit="5"
            sort="-createdAt "
            @filter="(form) => onSearch(form)" 
            @selectData="onSearchBack" 
            @cancel="dialog = false"
        />   

        <Search 
            :v-if="dialogEntity" 
            :open="dialogEntity" 
            :searchProgress="searchProgress"
            :title="$t('device.update.entity_select.title')" 
            :data="entities" 
            :pagination="pagination_entities"
            :initLimit="5"
            sort="-createdAt "
            @filter="(form) => onSearchEntity(form)" 
            @selectData="onSearchBackEntity" 
            @cancel="dialogEntity = false"
        />   

        <Confirmation
            :v-if="dialogClearPatient" 
            :open="dialogClearPatient"
            :content="$t('device.patient_tab.delete_content')"
            @no="dialogClearPatient = false; load()"
            @yes="clearPatient()"
        />

        <Confirmation
            :v-if="dialogConfirmPatient" 
            :open="dialogConfirmPatient"
            :content="$t('device.patient_tab.asign_content') + selectPatient.name + '?' "
            @no="dialogConfirmPatient = false; load()"
            @yes="save(); dialogConfirmPatient = false"
        /> 

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import ActionBar from "./components/ActionBar.vue";
import DangerZone from "./components/DangerZone.vue";
import SaveSnackBar from "./components/SaveSnackBar.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import Confirmation from "./dialogs/Confirmation.vue"
import validator from "../plugins/validator";
import * as deviceValidation from "../api/device/validation";
import Search from "./dialogs/Search.vue";
import copy from 'copy-to-clipboard';
import PatientVisitingCard from "./components/PatientVisitingCard.vue"
import HumanTime from "./components/HumanTime.vue";

export default {
    components: {
        Page,
        Card,
        ActionBar,
        Search,
        DangerZone,
        DangerZoneConfirm,
        Confirmation,
        PatientVisitingCard,
        HumanTime,
        SaveSnackBar
    },

    async mounted() {
        this.form.id = this.$route.params.id;
        this.form_calibration.id = this.$route.params.id;
        await this.load();
    },

    data: () => ({
        wifi: null,
        token: null,
        
        tab: 0,
   
        form: {
            id: null,
            selected_entity: null,
            name: null,
            info: null,
            sn: null,
            patient: null,
            enabled: null,
            tablet:{
                name: null,
                sn: null
            },
            additionalDevices: null,
        },

        errors:{
            entity: null,
            name: null,
            info: null,
            sn: null,
            patient: null,
        },

        form_calibration: {
            id: null,
            calibration_gyro_x: null,
            calibration_gyro_y: null,
            calibration_gyro_z: null,
        },

        errors_calibration:{
            calibration_gyro_x: null,
            calibration_gyro_y: null,
            calibration_gyro_z: null,
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        entity: null,
        updateTime: null,
        patientUpdateTime: null,
        calibrationUpdateTime: null,

        progress: true,
        searchProgress: false,
        saveProgress: false,
        saveCalibrationProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        copyAlert: false,

        dialog: false,
        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,
        dialogClearPatient: false,

        pagination_patients: {
            total: 0,
            filter: null
        },

        pagination_entities: {
            total: 0,
            filter: null
        },

        patients: null,
        patient: null,
        selectPatient: {
            _id: null,
            name: null
        },

        dialogEntity: false,
        entities: null,

        dialogConfirmPatient: false,

        save_snackbar: false
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            this.selectedEntity();

            const res = await this.$store.dispatch("api/device/get", {id: this.form.id});

            if (res.status == 200) {
                this.form.name = res.data.name;
                this.form.info = res.data.info;
                this.form.sn = res.data.sn;
                this.form.enabled = res.data.enabled;
                this.token = res.data.token;
                this.wifi = res.data.wifi;
                this.form.tablet.name = res.data.tablet.name;
                this.form.tablet.sn = res.data.tablet.sn;
                this.entity = res.data.entity.name;
                this.patient = res.data.patient;
                this.form.additionalDevices = res.data.additionalDevices;

                this.form_calibration.calibration_gyro_x = res.data.calibration_gyro_x;
                this.form_calibration.calibration_gyro_y = res.data.calibration_gyro_y;
                this.form_calibration.calibration_gyro_z = res.data.calibration_gyro_z;

                if(res.data.patient){
                    this.form.patient = res.data.patient._id;
                    this.selectPatient._id = res.data.patient._id;
                    this.selectPatient.name = res.data.patient.name;
                }

                this.updateTime = res.data.updateTime ? new Date(res.data.updateTime) : null;
                this.patientUpdateTime = res.data.patientUpdateTime ? new Date(res.data.patientUpdateTime) : null;
                this.calibrationUpdateTime = res.data.calibrationUpdateTime ? new Date(res.data.calibrationUpdateTime) : null;
                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);

            return validator(
                this.form,
                deviceValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        validateCalibration(check) {
            if(check) this.errors_calibration[check] = null;
            else Object.assign(this.$data.errors_calibration, this.$options.data(this).errors_calibration);

            return validator(
                this.form_calibration,
                deviceValidation.update_calibration,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors_calibration[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;
            this.form.patient = this.selectPatient._id ? this.selectPatient._id : null;
            this.selectedEntity();

            this.saveProgress = true;
            await this.$store.dispatch("api/device/update", this.form);

            if(this.form.selected_entity !== null && this.form.selected_entity !== this.selectedEntity()) {
                this.$router.push({ name: 'devices' });
                return;
            }

            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async saveCalibration() {
            if(!this.validateCalibration()) return;
            this.selectedEntity();

            this.saveCalibrationProgress = true;
            await this.$store.dispatch("api/device/update_calibration", this.form_calibration);
            await this.load();
            this.saveCalibrationProgress = false;
            this.save_snackbar = true;
        },

        async onSearch(form) {
            this.selectedEntity();

            if(this.pagination_patients.filter != form.filter) this.form.page = 1;
            this.pagination_patients.filter = form.filter;

            form.enabled = true;
            form.anonymised = false;

            let entity = this.selectedEntity();
            if(entity) form.entity = entity;

            this.searchProgress = true;
            const res = await this.$store.dispatch("api/patient/all", form);

            if (res.status == 200) {
                this.pagination_patients.total = res.pages;

                this.patients = [];
                
                res.data.forEach(item => { 
                    this.patients.push({
                        element: item,
                        table:{
                            name: {
                                value: item.name,
                                icon: "mdi-account"
                            },
                            birth_date: {
                                value: (new Date(item.birth_date)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}),
                                icon: "mdi-calendar-range"
                            },
                            national_id: {
                                value: item.national_id ? item.national_id : this.$t("empty"),
                                icon: "mdi-card-account-details-outline"
                            },
                            num_devices: {
                                value: " " + item.num_devices,
                                icon: "mdi-tablet-cellphone"
                            }
                        }
                    });
                });

                this.searchProgress = false;
            }
        },

        onSearchBack(data){
            this.dialogConfirmPatient = true;
            this.selectPatient._id = data.element._id;
            this.selectPatient.name = data.element.name;
        },

        clearPatient(){
            this.selectPatient._id = null;
            this.selectPatient.name = null;
            this.dialogClearPatient = false;
            this.save();
        },

        async onSearchEntity(form) {
            if(!this.user.hasPerm("entity")) return;

            if(this.pagination_entities.filter != form.filter) this.form.page = 1;
            this.pagination_entities.filter = form.filter;

            this.searchProgress = true;
            const res = await this.$store.dispatch("api/entity/all", form);

            if (res.status == 200) {
                this.pagination_entities.total = res.pages;

                this.entities = [];
                
                res.data.forEach(item => { 
                    this.entities.push({
                        element: item,
                        table:{
                            name: {
                                value: item.name,
                                icon: "mdi-account"
                            }
                        }
                    });
                });

                this.searchProgress = false;
            }
        },

        onSearchBackEntity(data){
            this.entity = data.element.name;
            this.form.selected_entity = data.element._id;
        },

        copyToClipboard(text) {
            this.copyAlert = copy(text);
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;
                    const res = await this.$store.dispatch("api/device/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'devices' })
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/device/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            this.form_calibration.entity = entity._id;
            return entity._id;
        }
    }
};
</script>
