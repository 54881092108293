<template>
    <v-simple-table class=" justify-center" v-if="patient">
        <template>
            <tbody>
                <tr>
                    <td>
                        {{ $t('patient_visiting_card.name') }}
                    </td>
                    <td>
                        {{ patient.name ? patient.name : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ $t('patient_visiting_card.phone') }}
                    </td>
                    <td>
                        {{ patient.phone ? patient.phone : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ $t('patient_visiting_card.email') }}
                    </td>
                    <td>
                        {{ patient.email ? patient.email : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                    {{ $t('patient_visiting_card.national_id') }}
                    </td>
                    <td>
                        {{ patient.national_id ? patient.national_id : $t('empty')}}
                    </td>
                </tr>
                <tr>
                    <td>
                    {{ $t('patient_visiting_card.birth_date') }}
                    </td>
                    <td>
                        {{ patient.birth_date ? (new Date(patient.birth_date)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                    {{ $t('patient_visiting_card.sex') }}
                    </td>
                    <td>
                        {{ patient.sex ? (patient.sex == "male") ? $t('patient_visiting_card.male') : $t('patient_visiting_card.female') : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                    {{ $t('patient_visiting_card.growth') }}
                    </td>
                    <td>
                        {{ patient.growth ? patient.growth : $t('empty') }}
                    </td>
                </tr>
                <tr>
                    <td>
                    {{ $t('patient_visiting_card.weight') }}
                    </td>
                    <td>
                        {{ patient.weight ? patient.weight : $t('empty') }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    props: {
        patient: {
            type: Object,
            default: null
        }
    }
};
</script>
