<template>
    <Dialog :title="$t('device.patient_tab.delete_title')" :open="open" @yes="$emit('yes')" @no="$emit('no')">
        {{ content }}
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog
    },

    
    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        content:{
            type: String,
            default: null
        }
    }

};
</script>
