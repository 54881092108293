<template>
    <Dialog :title="title" maxWidth="700px" :open="open" @cancel="$emit('cancel')">
        <v-text-field
            class="pa-0 ma-0"
            hide-details
            outlined
            :label="$t('search')"
            v-model="form.filter"
            @input="onKey"
        > 
            <template v-slot:append>
                <v-progress-circular
                    v-if="searchProgress"
                    size="20"
                    width="2"
                    color="primary"
                    indeterminate
                />
                <v-icon
                    v-if="!searchProgress"
                    color="primary"
                >
                    mdi-magnify
                </v-icon>
            </template>
        </v-text-field>

        <template>
            <div class="mt-6 text-center text-subtitle-2 grey--text" v-if="!data || (data && data.length == 0)">
                {{$t("empty_list")}}
            </div>
            
            <v-simple-table class="mt-5" v-if="data && data.length > 0">
                <tbody style="white-space: nowrap;">
                    <tr v-for="(i, index) in data" :key="index" @click="selectRow(i)" style="cursor: pointer">
                        <td v-for="(t, index) in i.table" :key="index">
                            <v-icon class="mr-1" size="20">{{ t.icon }}</v-icon>{{ t.value }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>

             <v-pagination
                class="mt-3"
                @input="$emit('filter', form);"
                v-if="pagination && pagination.total > 1"
                v-model="form.page"
                :length="pagination.total"
                :total-visible="7"
            />
        </template>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        searchProgress: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        initLimit: {
            type: Number,
            default: 0
        },

        sort: {
            type: String,
            default: null
        },

        data: {
            type: Array,
            default: null
        },

        pagination: {
            type: Object,
            default: () => ({
                total: 0,
                filter: null
            })
        },
    },

    mounted(){
        this.form.sort = this.sort;

        if(this.initLimit > 0) {
            this.form.limit = this.initLimit;
            this.$emit('filter', this.form);
        }
    },

    data: () => ({
        form: {
            sort: null,
            page: 1,
            limit: 5,
            filter: null
        },

        lastLength: 0,
        lastTime: Date.now(),
        timeout: null
    }),

    methods: {
        onKey(){
            if(Date.now() - this.lastTime < this.$store.state.config.searchTimeDelay){
                clearTimeout(this.timeout);
            }
            
            this.timeout = setTimeout(() => {
                if(this.form.filter.length > 2) {
                    this.$emit('filter', this.form);
                }

                if(this.form.filter.length == 0 && this.lastLength > 0) {
                    this.form.limit = this.initLimit;
                    this.form.filter = null;
                    this.$emit('filter', this.form);
                }

                this.lastLength = (this.form.filter) ? this.form.filter.length : 0;
            }, this.$store.state.config.searchTimeDelay);
            
            this.lastTime = Date.now();
        },

        selectRow(item){
            this.$emit('selectData', item);
            this.$emit("cancel");
        },     
    }
};
</script>
