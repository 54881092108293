<template>
    <v-card class="rounded-0" elevation="1">
        <v-card-text v-if="hasFilter && !hasSlotDefault && !hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-12 justify-start align-center pl-3">
                    <v-text-field
                        class="pa-0 ma-0"
                        hide-details
                        outlined
                        :label="$t('search')"
                        v-model="filter"
                        @input="onKey"
                    > 
                        <template v-slot:append>
                            <v-progress-circular
                                v-if="searchProgress"
                                size="20"
                                width="2"
                                color="primary"
                                indeterminate
                            />
                            <v-icon
                                v-if="!searchProgress"
                                color="primary"
                            >
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="hasFilter && hasSlotDefault && !hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-6 justify-start align-center pl-3">
                    <v-text-field
                        class="pa-0 ma-0"
                        hide-details
                        outlined
                        :label="$t('search')"
                        v-model="filter"
                        @input="onKey"
                    > 
                        <template v-slot:append>
                            <v-progress-circular
                                v-if="searchProgress"
                                size="20"
                                width="2"
                                color="primary"
                                indeterminate
                            />
                            <v-icon
                                v-if="!searchProgress"
                                color="primary"
                            >
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>

                <v-col class="d-flex col-12 col-md-6 justify-end align-center pr-3">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="hasFilter && hasSlotDefault && hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-4 justify-start align-center pl-3">
                    <slot name="left"></slot>
                </v-col>
                <v-col class="d-flex col-12 col-md-4 justify-center align-center">
                    <v-text-field
                        class="pa-0 ma-0"
                        hide-details
                        outlined
                        :label="$t('search')"
                        v-model="filter"
                        @input="onKey"
                    > 
                        <template v-slot:append>
                            <v-progress-circular
                                v-if="searchProgress"
                                size="20"
                                width="2"
                                color="primary"
                                indeterminate
                            />
                            <v-icon
                                v-if="!searchProgress"
                                color="primary"
                            >
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col class="d-flex col-12 col-md-4 justify-end align-center pr-3">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="!hasFilter && hasSlotDefault && hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-6 justify-start align-center pl-3">
                    <slot name="left"></slot>
                </v-col>
                <v-col class="d-flex col-12 col-md-6 justify-end align-center pr-3">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="hasFilter && !hasSlotDefault && hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-4 justify-start align-center pl-3">
                    <slot name="left"></slot>
                </v-col>
                <v-col class="d-flex col-12 col-md-8 justify-end align-center pr-3">
                    <v-text-field
                        class="pa-0 ma-0"
                        hide-details
                        outlined
                        :label="$t('search')"
                        v-model="filter"
                        @input="onKey"
                    > 
                        <template v-slot:append>
                            <v-progress-circular
                                v-if="searchProgress"
                                size="20"
                                width="2"
                                color="primary"
                                indeterminate
                            />
                            <v-icon
                                v-if="!searchProgress"
                                color="primary"
                            >
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="!hasFilter && hasSlotDefault && !hasSlotLeft && !updateTime">
            <v-row>
                <v-col class="d-flex col-12 col-md-12 justify-end align-center pr-3">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="!hasFilter && hasSlotDefault && !hasSlotLeft && updateTime">
            <v-row>
                <v-col class="d-flex col-6 justify-start align-center pl-3">
                    <span class="d-none d-md-flex font-weight-medium mr-1">
                        {{ $t('last_update') }}
                    </span>
                    <span class="d-none d-md-flex font-weight-regular">
                        {{ updateTimeToString() }}
                    </span>
                    <span class="d-flex d-md-none font-weight-medium">
                        <v-icon left>mdi-update</v-icon>
                    </span>
                    <span class="d-flex d-md-none font-weight-regular">
                        {{ updateTimeToString(true) }}
                    </span>
                </v-col>
                <v-col class="d-flex col-6 justify-end align-center pr-3">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="!hasFilter && !hasSlotDefault && !hasSlotLeft && updateTime">
            <v-row>
                <v-col class="d-flex col-6 justify-start align-center pl-3">
                    <span class="d-none d-md-flex font-weight-medium mr-1">
                        {{ $t('last_update') }}
                    </span>
                    <span class="d-none d-md-flex font-weight-regular">
                        {{ updateTimeToString() }}
                    </span>
                    <span class="d-flex d-md-none font-weight-medium">
                        <v-icon left>mdi-update</v-icon>
                    </span>
                    <span class="d-flex d-md-none font-weight-regular">
                        {{ updateTimeToString(true) }}
                    </span>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-text v-if="!hasFilter && !hasSlotDefault && hasSlotLeft">
            <v-row>
                <v-col class="d-flex col-12 col-md-12 justify-start align-center pl-3">
                    <slot name="left"></slot>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    props: {
        updateTime: {
            type: Date,
            default: null
        },

        searchProgress: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        filter: null,
        classOnlyFilter: null,
        defaultSlot: false,
        lastTime: Date.now(),
        timeout: null
    }),

    computed: {
        hasSlotDefault() {
            return this.$slots.default;
        },
        hasSlotLeft() {
            return this.$slots.left;
        },
        hasFilter() {
            return this.$listeners && this.$listeners.filter;
        }
    },

    methods: {
        updateTimeToString(short = false) {

            let diff = (Date.now() - (this.updateTime.getTime() - 1000 * this.updateTime.getSeconds() - this.updateTime.getMilliseconds()))/1000;

            let counter = "";

            if (diff < 60) { counter = " (" + this.$t('time_units.less_than') + " " + this.$t('time_units.mins.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 60 && diff < 2*60) { counter = " (" + this.$t('time_units.mins.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 2*60 && diff < 60*60) { counter = " (" + parseInt(diff/60) + " " + this.$t('time_units.mins.plural') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 60*60 && diff < 2*60*60) { counter = " (" + this.$t('time_units.hours.single') + " " + this.$t('time_units.ago') + ")"; }
            else if (diff >= 2*60*60 && diff < 24*60*60) { counter = " (" + parseInt(diff/(60*60)) + " " + this.$t('time_units.hours.plural') + " " + this.$t('time_units.ago') + ")"; }

            if (short) {
                return counter.length == 0 
                    ? this.updateTime.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) 
                    : counter.substring(2, counter.length - 1);
            } else {
                return this.updateTime.toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}) + " " + 
                this.updateTime.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit",}) + counter;
            }
        },

        onKey(){
            if(Date.now() - this.lastTime < this.$store.state.config.searchTimeDelay){
                clearTimeout(this.timeout);
            }
            
            this.timeout = setTimeout(() => {
                this.$emit('filter', this.filter);
            }, this.$store.state.config.searchTimeDelay);
            
            this.lastTime = Date.now();
        }
    }
};
</script>
